<template>
  <div>
    <div class="d-flex justify-content-between mb-1">

      <b-button
        v-b-toggle.unitfilter
        variant="outline-primary"
      >
        Filters
      </b-button>
    </div>

    <b-collapse
      id="unitfilter"
      class="mt-2"
    >
      <unit-filter
        :filter="filter"
        @filterUnit="recieveData"
      />
    </b-collapse>
    <b-card>
      <b-table
        sticky-header
        responsive="true"
        :items="items.data"
        :fields="vendorFields"
        :per-page="items.per_page"
        :sort-by.sync="filter.sortBy"
        :sort-direction="filter.sortOrder"
        @row-contextmenu="contextmenu"
        @sort-changed="sortChanged"
      >
        <template
          v-if="items.total == 0"
          #table-caption
        >
          <div
            class="text-center"
          >
            No Record Found
          </div>
        </template>
      </b-table>
      <b-row v-if="items.total">

        <b-col
          lg="4"
          class="d-flex"
        >
          <div class="align-self-center pr-2">
            Total Entries {{ items.total }}
          </div>

          <b-form-group class="mb-0 align-self-center">
            <label class="d-inline-block text-sm-left mr-50">Per page</label>
            <b-form-select
              id="perPageSelect"
              v-model="filter.size"
              size="sm"
              :options="tableFilter.pageOptions"
              class="w-70"
              @change="getUnitList"
            />
          </b-form-group>

        </b-col>
        <b-col lg="8">
          <pagination
            :data="items"
            :limit="2"
            class="pt-1 justify-content-end"
            @pagination-change-page="getResults"
          />
        </b-col>

      </b-row>
    </b-card>

    <vue-context
      ref="vendorContextMenu"
    >
      <li
        v-for="data in menuData"
        :key="data.text"
      >
        <b-link
          v-if="data.text == 'View Vendor' && checkPermission(permissions.LEAD_MORE_DETAILS)"
          class="d-flex align-items-center"
          @click="optionClicked(data.text)"
        >
          <feather-icon
            :icon="data.icon"
            size="16"
          />
          <span class="ml-75">{{ data.text }}</span>
        </b-link>
        <b-link
          v-else-if="data.text != 'View Vendor'"
          class="d-flex align-items-center"
          @click="optionClicked(data.text)"
        >
          <feather-icon
            :icon="data.icon"
            size="16"
          />
          <span class="ml-75">{{ data.text }}</span>
        </b-link>
      </li>
    </vue-context>
    <add-unit
      :editunitdata="valuesContext"
      @loadUnits="loadUnits"
    />
  </div>
</template>

<script>
import axios from 'axios'
// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BTable, BCard, BLink, BCollapse, VBToggle, BButton, BRow, BCol, VBTooltip, BFormGroup, BFormSelect,
} from 'bootstrap-vue'
import VueContext from 'vue-context'
import store from '@/store'
import constants from '@/constants'
import UnitFilter from './UnitFilter.vue'
import AddUnit from './AddUnit.vue'
import checkPermissions from '@/utils/checkPermissons'
import permissions from '@/permissions'
import { showLoader, hideLoader, tableFilter } from '@/utils/common'

export default {
  name: 'UnitList',
  components: {
    BLink,
    BCard,
    BRow,
    BTable,
    VueContext,
    BCollapse,
    BButton,
    AddUnit,
    BCol,
    BFormGroup,
    BFormSelect,
    UnitFilter,
  },
  directives: {
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
  },
  setup() {
    return {
      permissions,
    }
  },
  data() {
    return {
      tableFilter,
      constants,
      items: [],
      valuesContext: {},
      filter: {
        vendor_id: this.$route.params.id,
        params: {
          unit_title: '',
          vendor_unit_code: '',
          city_name: '',
          city: '',
          zipcode: '',
        },
        size: '10',
        totalElements: '',
        totalPages: '',
        sortBy: 'id',
        sortOrder: 'desc',
      },
      editUnitData: {
        vendor_id: '',
        vendor_unit_id: '',
      },
      vendorFields: [
        { key: 'id', label: 'Id', sortable: true },
        { key: 'vendor_unit_code', label: 'Unit Code', sortable: true },
        { key: 'unit_title', label: 'Unit Title', sortable: true },
        { key: 'vendor_name', label: 'Vendor Name', sortable: true },
        { key: 'address', label: 'Address', sortable: true },
        { key: 'country_name', label: 'Country', sortable: true },
        { key: 'state_name', label: 'State', sortable: true },
        { key: 'city_name', label: 'City', sortable: true },
        { key: 'zipcode', label: 'Zip Code', sortable: true },
      ],
      menuData: [
        { icon: 'EditIcon', text: 'Edit Unit' },
      ],
    }
  },
  mounted() {
  },
  created() {
    this.getUnitList()
  },
  methods: {
    checkPermission(val) {
      return checkPermissions(val)
    },
    sortChanged(val) {
      this.filter.sortBy = val.sortBy
      this.filter.sortOrder = val.sortDesc ? 'desc' : 'asc'
      this.getUnitList()
    },
    async getUnitList() {
      showLoader()
      await store.dispatch('unitStore/getUnitList', this.filter).then(response => {
        this.items = response.data.data
      })
      hideLoader()
    },
    redirectToCreate() {
      this.$router.push('/admin/create-users')
    },
    contextmenu(item, index, event) {
      event.preventDefault()
      this.valuesContext = item
      this.$refs.vendorContextMenu.open(event, item)
    },
    optionClicked(text) {
      if (text === 'View Details') {
        this.$router.push(`/vendor/${this.valuesContext.vendor_id}`)
      } else if (text === 'Edit Unit') {
        this.editUnitData.vendor_id = this.$route.params.id
        this.editUnitData.vendor_unit_id = this.valuesContext.vendor_unit_id
        this.$bvModal.show('addUnit')
      }
    },
    recieveData(val) {
      this.filter = val
      this.getUnitList()
    },
    getResults(page = 1) {
      // eslint-disable-next-line no-undef
      axios.post(`${constants.COMPANY_API_PREFIX}/vendor-unit/vendor-unit-list?page=${page}`, this.filter, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      })
        .then(response => {
          this.items = response.data.data
        })
    },
    loadUnits() {
      this.getUnitList()
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-context.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

</style>
