<template>
  <b-form
    autocomplete="off"
    @submit.prevent="filterUnitSubmit"
  >
    <b-row class="mb-2">
      <b-col cols="3">
        <b-form-group>
          <b-input-group class="input-group-merge">
            <b-form-input
              id="vi-unit-title"
              v-model="form.params.unit_title"
              type="text"
              autocomplete="off"
              :placeholder="t('Unit Title')"
            />
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col cols="3">
        <b-form-group>
          <b-form-input
            id="vi-unit-code"
            v-model="form.params.vendor_unit_code"
            type="text"
            autocomplete="off"
            :placeholder="t('Unit Code')"
          />
        </b-form-group>
      </b-col>
      <b-col cols="3">
        <b-form-group>
          <b-input-group class="input-group-merge">
            <vue-autosuggest
              ref="city_name"
              v-model="form.params.city_name"
              :suggestions="cityOptions"
              :input-props="{id: 'autosuggest__input_ajax',
                             placeholder: t('City'),
                             class: 'form-control',}"
              :render-suggestion="renderCitySuggestion"
              :get-suggestion-value="renderCitySuggestion"
              @selected="changeCityAutosuggest"
              @input="suggestForm"
            />
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col cols="3">
        <b-form-group>
          <b-input-group class="input-group-merge">
            <b-form-input
              id="vi-zip-code"
              v-model="form.zipcode"
              type="text"
              autocomplete="off"
              :placeholder="t('Zip Code')"
            />
          </b-input-group>
        </b-form-group>
      </b-col>
      <!-- <b-col cols="3">
        <b-form-group>
          <v-select
            v-model="form.period"
            class="p-0 border-0"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="label"
            :reduce="op => op.value"
            :placeholder="t('Period')"
            :options="periodOptions[$i18n.locale]"
            @input="onChangePeriod"
          />
        </b-form-group>
      </b-col> -->
      <!-- <b-col cols="3">
        <b-form-group>
          <b-input-group class="input-group-merge">
            <flat-pickr
              v-model="form.start_date"
              :placeholder="t('Select Start Date')"
              class="form-control"
              :class="isDateDisable ? 'pointer-events-none' : 'remove-readonly'"
              :config="{ dateFormat: 'Y-m-d'}"
            />
          </b-input-group>
        </b-form-group>
      </b-col> -->
      <!-- <b-col cols="3">
        <b-form-group>
          <b-input-group class="input-group-merge">
            <flat-pickr
              v-model="form.end_date"
              class="form-control"
              :placeholder="t('Select End Date')"
              :class="isDateDisable ? 'pointer-events-none' : 'remove-readonly'"
              :config="{ dateFormat: 'Y-m-d'}"
            />
          </b-input-group>
        </b-form-group>
      </b-col> -->
      <b-col
        cols="12"
        class="text-center"
      >
        <b-button
          variant="success mx-1"
          type="submit"
        >
          {{ t('Search') }}
        </b-button>
        <b-button
          variant="danger mx-1"
          @click="resetFilter"
        >
          {{ t('Reset') }}
        </b-button>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import { VueAutosuggest } from 'vue-autosuggest'
import {
  BRow, BCol, BInputGroup, BFormGroup, BButton, BForm, BFormInput,
} from 'bootstrap-vue'
import { useUtils } from '@core/libs/i18n'
import store from '@/store'

export default {
  components: {
    BForm,
    BRow,
    BCol,
    // vSelect,
    // flatPickr,
    BFormInput,
    BInputGroup,
    BFormGroup,
    BButton,
    VueAutosuggest,
    // BCollapse,
  },
  setup() {
    const { t } = useUtils()
    return { t }
  },
  props: ['filter'],
  data() {
    return {
      isDateDisable: true,
      suggestions: [],
      vendorOptions: [{
        vendor_type_name: 'Company',
        vendor_type_id: 1,
      }, {
        vendor_type_name: 'Individual',
        vendor_type_id: 2,
      }],
      customerTypeOptions: [],
      cityOptions: [],
      form: {
        vendor_id: this.$route.params.id,
        params: {
          unit_title: '',
          vendor_unit_code: '',
          city_name: '',
          city: '',
          zipcode: '',
        },
        size: '10',
        totalElements: '',
        totalPages: '',
        sortBy: 'id',
        sortOrder: 'desc',
      },

    }
  },
  watch: {
    filter(val) {
      this.form = val
    },
  },
  methods: {
    SearchLeadData() {
      this.$emit('filterUnit', this.form)
    },
    suggestForm(val) {
      this.form.city = ''
      if (val.length >= 3) {
        store.dispatch('getCountryList/getCityAuto', { city: val }).then(response => {
          if (response.data.code === '200') {
            this.cityOptions.splice(0, this.cityOptions.length)
            this.cityOptions.push({ data: response.data.data })
            this.selected = null
          }
        })
      }
    },
    resetFilter() {
      this.form = {
        vendor_id: this.$route.params.id,
        params: {
          unit_title: '',
          vendor_unit_code: '',
          city_name: '',
          city: '',
          zipcode: '',
        },
        size: '10',
        totalElements: '',
        totalPages: '',
        sortBy: 'vendor_adress_unit_details.id',
        sortOrder: 'asc',
      }
    },
    filterUnitSubmit() {
      this.$emit('filterUnit', this.form)
    },
    changeCityAutosuggest(val) {
      this.form.city = val.item.location_id
      this.form.city_name = val.item.city
      this.$emit('getCreateUser', this.form)
    },
    renderCitySuggestion(suggestion) {
      return suggestion.item.city
    },
  },

}
</script>

<style>
</style>
